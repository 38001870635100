import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'

type FormData = {
  cpf: string
  ticket: string
  isTicketValid: boolean
  fullName: string
  email: string
  emailConfirmation: string
  phone: string
  cnpj: string
  companyName: string
  message: string
}

type Container = {
  width: number
  height: number
}

type AppStateContextType = {
  formState: FormData
  setFormState: Dispatch<SetStateAction<FormData>>
  currentStep: number
  setCurrentStep: Dispatch<SetStateAction<number>>
  container: Container
  setContainer: Dispatch<SetStateAction<Container>>
  cancelModalOpen: boolean
  setCancelModalOpen: Dispatch<SetStateAction<boolean>>
  resetState: () => void
}

type AppStateProviderProps = {
  children: ReactNode
}

export const AppStateContext = createContext({} as AppStateContextType)

const initialFormState: FormData = {
  cpf: '',
  ticket: '',
  isTicketValid: false,
  fullName: '',
  email: '',
  emailConfirmation: '',
  phone: '',
  cnpj: '',
  companyName: '',
  message: '',
}

export function AppStateProvider({ children }: AppStateProviderProps) {
  const [currentStep, setCurrentStep] = useState(1)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [container, setContainer] = useState({
    width: 0,
    height: 0,
  })
  const [formState, setFormState] = useState<FormData>(initialFormState)

  function resetState() {
    setFormState(initialFormState)
  }

  return (
    <AppStateContext.Provider
      value={{
        formState,
        setFormState,
        currentStep,
        setCurrentStep,
        container,
        setContainer,
        cancelModalOpen,
        setCancelModalOpen,
        resetState,
      }}
    >
      {children}
    </AppStateContext.Provider>
  )
}

export function useAppState() {
  const context = useContext(AppStateContext)

  return context
}
